import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastService } from 'src/app/services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class HandleHttpErrorService {
  constructor(
    private authService: AuthService,
    private toastService: ToastService
  ) {}

  handleError(err: HttpErrorResponse) {
    if (err.status === 401) {
      this.authService.logout();
      return;
    }

    this.toastService.showErrorToast(err);
  }
}
