<ion-menu-toggle auto-hide="false" *ngFor="let item of menu()">
  <ion-item
    routerDirection="root"
    [routerLink]="[item.url]"
    lines="none"
    detail="false"
    routerLinkActive="selected"
  >
    <ion-icon
      aria-hidden="true"
      slot="start"
      [ios]="item.icon"
      [md]="item.icon"
    ></ion-icon>
    <ion-label>{{ item.title | translate | titlecase }}</ion-label>
  </ion-item>
</ion-menu-toggle>
