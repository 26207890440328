import { Injectable } from '@angular/core';
import { SalesforceClientService } from './salesforce-client.service';

@Injectable({
  providedIn: 'root',
})
export class FleetUserService {
  constructor(private salesforceClientService: SalesforceClientService) {}

  getCurrentUser() {
    return this.salesforceClientService.fetchCurrentUser();
  }
}
